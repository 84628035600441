import { forwardRef } from "react"

import "./Message.sass"

function Message({ message }, ref) {
  return (
    <div
      className="Message"
      dangerouslySetInnerHTML={{ __html: message }}
      ref={ref}
    />
  )
}

export default forwardRef(Message)
