import React, { Component } from "react"
import classNames from "classnames"

import Hr from "./Hr"
import { ReactComponent as InstagramSVG } from "../assets/instagram.svg"

import "./Footer2.sass"

class Footer extends Component {
  state = {
    isSubmitting: false,
    isSubmitted: false,
    isError: false,
  }

  handleSubmission = (e) => {
    e.preventDefault()

    this.setState({ isSubmitting: true })

    // const timestamp = Date.now()
    // const el = document.getElementById("email_input")

    // firebase
    //   .database()
    //   .ref(`subscriptions/${timestamp}`)
    //   .set({ email: el.value })
    //   .then(
    //     (success) => this.setState({ isSubmitted: true, isSubmitting: false }),
    //     (error) => this.state({ isError: true, isSubmitting: false }),
    //   )
  }

  render() {
    // const { isSubmitting, isSubmitted, isError } = this.state

    const footerClassName = classNames({
      Footer2: true,
      // isInitialized: !isSubmitted,
      // isSubmitted: isSubmitted,
      // isError: isError,
    })

    return (
      <footer className={footerClassName}>
        <Hr />
        <div className="footer-layout">
          <div className="footer-item">
            <h5>A.Weeber &amp; A.Cobb GbR</h5>
            <p>Gartenstrasse 2</p>
            <p>10115 Berlin</p>
          </div>
          <div className="footer-item">
            <p>
              <a href="mailto:mail@alexxandanton.com">mail@alexxandanton.com</a>
            </p>
            <p>
              <a href="tel:+49 176 22 04 25 46">+49 176 22 04 25 46</a>
            </p>
            <p>
              <a href="tel:+49 30 60 93 65 92">+49 30 60 93 65 92</a>
            </p>
          </div>
          <div className="footer-item instagram">
            <p>
              <a
                href="https://www.instagram.com/alexxandanton/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <InstagramSVG className="isvg" />
                <span className="handle">@alexxandanton</span>
              </a>
            </p>
          </div>
          <div className="footer-item copyright">
            <form
              className="email-form"
              onSubmit={this.handleSubmission}
              style={{ display: "none" }}
            >
              <input
                id="email_input"
                type="text"
                name="email-address"
                placeholder="Enter your email address"
                // disabled={isSubmitting}
              />
              <input type="submit" value="Subscribe" />
            </form>
            <h5>
              &copy; Alexx And Anton - All rights reserved{" "}
              {new Date().getFullYear()}{" "}
            </h5>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
