import { forwardRef } from "react"

import "./HomeLayout.sass"

function HomeLayout({ children, fixedElem }, ref) {
  return (
    <div className="HomeLayout" ref={ref}>
      <div className="fixed-element">{fixedElem}</div>

      <div className="content">{children}</div>
    </div>
  )
}

export default forwardRef(HomeLayout)
