import { useState, useEffect } from "react"
import FontFaceObserver from "fontfaceobserver"

import data from "./data.json"
import draft from "./draft.json"

import "./App.sass"
import Home from "./Home"

function App() {
  const [isImageLoaded, setImageLoaded] = useState(false)
  const [isFontLoaded, setFontLoaded] = useState(false)

  const postData = window.location.search === "?draft" ? draft : data

  useEffect(() => {
    const img = new Image()
    img.onload = () => {
      setImageLoaded(true)
    }
    img.src = `/${postData.image}`
  }, [postData.image])

  useEffect(() => {
    // Observe loading of Ano (to remove Ano, remove the <link> tag in
    // the index.html file and this observer)
    const fontObserver = new FontFaceObserver("Ano Half", {})
    // When Ano is loaded, add a font-family using CSS & dispatch FONT_LOADED
    fontObserver.load().then(
      () => {
        setFontLoaded(true)
      },
      () => {
        setFontLoaded(false)
      },
    )
  }, [])

  if (!(isImageLoaded && isFontLoaded)) {
    return false
  } else
    return (
      <Home
        image={postData.image}
        quote={postData.title}
        author={postData.quote_author}
        message={postData.body}
      />
    )
}

export default App
