import { useRef, useState, useEffect, useMemo } from "react"

import HomeLayout from "./components/HomeLayout"
import Piece from "./components/Piece"
import Quote from "./components/Quote"
import MainLogo from "./components/MainLogo"
import Message from "./components/Message"
import Footer2 from "./components/Footer2"

export default function Home({ image, quote, author, message }) {
  const homeRef = useRef(null)
  const messageRef = useRef(null)

  // eslint-disable-next-line no-unused-vars
  const [showPiece, setShowPiece] = useState(true)

  let options = useMemo(
    () => ({
      root: homeRef.current,
      threshold: [0, 1.0],
    }),
    [homeRef],
  )

  useEffect(() => {
    if (!messageRef.current) {
      return false
    }

    const callback = (matches) => {
      if (Array.isArray(matches) && matches[0] && matches[0].isIntersecting) {
        setShowPiece(false)
      } else {
        setShowPiece(true)
      }
    }
    const observer = new IntersectionObserver(callback, options)
    let target = messageRef.current
    observer.observe(target)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <HomeLayout
      fixedElem={<Piece isHidden={!showPiece} image={image} />}
      ref={homeRef}
    >
      <Quote quote={quote} author={author} />

      <MainLogo />

      <Message message={message} ref={messageRef} />

      <Footer2 />
    </HomeLayout>
  )
}
