import classNames from "classnames"

// import squareImg from "../assets/aa-square.jpg"
// import landscapeImg from "../assets/aa-landscape.jpg"

import "./Piece.sass"

export default function Piece({ isHidden, image }) {
  const pieceClassName = classNames({
    Piece: true,
    isHidden,
  })
  return (
    <div className={pieceClassName}>
      <div className="image">
        <img src={`/${image}`} alt="" />
      </div>
    </div>
  )
}
