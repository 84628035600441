import "./Quote.sass"

export default function QuoteText({ quote, author }) {
  return (
    <div className="Quote">
      <div className="quote-statement">{quote}</div>
      {author && <div className="quote-author">{author}</div>}
    </div>
  )
}
